'use client'

import React from 'react'
import { ErrorPage } from 'components/error-page/error-page'
import { BaseLayout } from 'components/layouts/base-layout/base-layout'
import { baseLayoutDataMapper } from 'utils/data'
import { pageCommonFetcher, QueryName } from 'utils/url'
import useSWR from 'swr'
import { useParams } from 'next/navigation'
import { Language } from 'generated/sdk'

const NotFound = () => {
	const params = useParams()
	const lang: Language = params?.slug ? (params.slug[0] === 'pt' ? Language.Pt : Language.En) : Language.Pt

	const commonQuery: QueryName = 'PageCommon'

	const { data, error } = useSWR([commonQuery, lang], pageCommonFetcher, {
		dedupingInterval: 0,
	})

	if (!data || error) {
		return <ErrorPage statusCode={500} />
	}

	const { headerProps, footerProps } = baseLayoutDataMapper(data, lang)

	return (
		<BaseLayout headerProps={headerProps} footerProps={footerProps}>
			<ErrorPage statusCode={404} />
		</BaseLayout>
	)
}

export default NotFound
